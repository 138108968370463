import React, { Component } from 'react';

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from './section';
import Footer from "../../components/Footer/footer";
import Contact from "../../components/Contact/Contact";
//THE TWO BELOW THE SECTIONS ADDS HOW DOES IT WORK + PRODUCT GENERAL DESCRIPTION
import AboutUs from "../../components/AboutUs/AboutUs";
import Process from "../../components/Process/Process";

// import { useState } from 'react';
// import { send } from 'emailjs-com';
import{ init } from 'emailjs-com';
init("user_Gt42pIeH0aS5lgV5HIpNu");

class Index6 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems : [
                { id: 1 , idnm : "process", navheading: "How It Works" },
                { id: 2 , idnm : "contact", navheading: "Get In Touch" },
            ],
            navClass : ""
        };
    }
    
    render() {
        return (
            <React.Fragment>

                {/* Importing Navbar */}
                <NavbarPage navItems={this.state.navItems} navClass={this.state.navClass} />

                {/* section */}
                <Section/>
                {/* process */}
                <Process/>
                {/* about us */}
                <AboutUs/>
                {/* Contact */}
                 <Contact/>
                {/* footer */}
                <Footer/>
            </React.Fragment>
        );
    }
}

export default Index6;
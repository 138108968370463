import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Modal from 'react-awesome-modal';


const Alert_text_Style = {
  color: 'black',
  paddingLeft: "36px",
  
};

const Alert_header_Style = {
  color: 'black',
  marginLeft: "28%",  
};

const Close_text_Style = {
  marginLeft: "45%",
  marginTop: "40px",
  marginBottom: "30%",

};

class FooterLinks extends Component {

  constructor(props) {
    super(props);
    this.state = {
        visible : false
    }
}

openModal() {
  this.setState({
      visible : true
  });
}

closeModal() {
  this.setState({
      visible : false
  });
}


  render() {
    return (
      <React.Fragment>
        <div className="footer-alt">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="float-start pull-none">
                  <p className="copy-rights text-muted">
                    2021 © Fountain Pen | 8 The Green, Dover, Delaware
                    <a href="/#" className="copy-rights text-muted" onClick={() => this.openModal()}> <br /> Privacy Policy | Terms and Conditions | Disclaimer</a>
                    
                    </p>
                    
                  <Modal
                    visible={this.state.visible}
                    width="650px"
                    height="450px"
                    effect="fadeInUp"
                    onClickAway={() => this.closeModal()}
                >
                    <div className="mt-4 pt-4">
                        <h3 className="home-title" style={Alert_header_Style}><br /> Coming soon</h3>
                        <p className="pt-3 home-desc" style={Alert_text_Style}> In the meantime, for information regarding our policies please email us at: admin@fountainpen.ai <br /> </p>
                        
                        <a href="/#" className="btn-primary btn btn-none;height: 20px" style={Close_text_Style} onClick={() => this.closeModal()}> Close </a> <br /><br />
                    </div>
                </Modal>
                </div>
                <div className="float-end pull-none">
                </div>
                <div className="clearfix"></div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FooterLinks;

import React, { Component } from "react";
import { Container, Row, Col, FormGroup } from "reactstrap";
// import { AvForm, AvField } from "availity-reactstrap-validation";
import emailjs from "emailjs-com";
//Import Section Title
import SectionTitle from "../common/section-title";
import{ init } from 'emailjs-com';
import Modal from 'react-awesome-modal';

import { Formik, Form, Field, ErrorMessage } from 'formik';
init("user_Gt42pIeH0aS5lgV5HIpNu");
// var http = require("http");

// http.createServer(function(request, response) {
//   var ip = request.headers['x-forwarded-for'] || request.connection.remoteAddress;

//   console.log(ip)
//   response.writeHead(200, {"Content-Type": "text/plain"});
//   response.write("Hello World");
//   response.end();
// }).listen(8000);


// var email = req.body.email;
// var password = req.body.password;
// function handleClick() {
//   console.log(email)
//   var data = {
//     to_email:email,
//   };

//   emailjs.send(service_hzyawq5, template_4trm9uk, data, user_Gt42pIeH0aS5lgV5HIpNu).then(
//     function (response) {
//       console.log(response.status, response.text);
//     },
//     function (err) {
//       console.log(err);
//     }
//   );
// }
const Message_text_Style = {

  
};


const Alert_text_Style = {
  color: 'black',
  marginLeft: "25%",

};

const Close_text_Style = {
  marginLeft: "40%",
  marginTop: "40px",
};



class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openModal() {
    this.setState({
        visible : true
    });
  }
  
  closeModal() {
    this.setState({
        visible : false
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="section " id="contact">
          <Container>
            {/* section title */}
            <SectionTitle
              title="GET IN TOUCH"
              desc="We'd love to collaborate with other innovative companies and creative individuals."
            />

            <Row>
              {/* <Col lg={4} >
                <div className="mt-4 pt-4">
                  <p className="mt-4">
                    <span className="h5">Our mailing adress:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                    8 The Green (Suite B), Dover, Delaware ZIP 19901
                    </span>
                  </p>
                  <p className="mt-4">
                    <span className="h5">Our contact informaion:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                    Info@fountainpen.ai
                    </span>
                  </p>
                  <p className="mt-4">
                    <span className="h5">Working Hours:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                      9:00AM To 6:00PM CST
                    </span>
                  </p>
                </div>
              </Col> */}
              <Col lg={{ size: 8, offset: 2 }}>
                <div className="custom-form mt-4 pt-4">
                  <div id="message"></div>
                                   
                  <Formik

       initialValues={{ user_name: '', user_email: '', subject: '', message: '' }}

       validate={values => {

         const errors = {};

         if (!values.email) {

           errors.email = 'Required';

         } else if (

           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)

         ) {

           errors.email = 'Invalid email address';

         }

         return errors;

       }}

       onSubmit={(values, { setSubmitting }) => {

         setTimeout(() => {

           emailjs.send("service_hzyawq5","template_3xwi0iq",{
            name: values.user_name,
            subject: values.subject,
            message: values.message,
            email: values.email,
            });
           setSubmitting(false);
          //  alert(JSON.stringify('Thank you for reaching out. we will be in touch soon.'));
          
            document.getElementById("contact-form").reset();
            this.openModal()

                        

        

         }, 400);

       }}

     >

       {({ isSubmitting }) => (

         <>
         
         
         <Form name="contact-form" id="contact-form">
                    <Row>
                      <Col lg={6}>
                        <FormGroup className="mt-2">
                          <Field
                            required
                            name="user_name"
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder="Your name*"
                          />
                            <ErrorMessage name="user_name" component="div" />

                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup className="mt-2">
                          <Field
                            required
                            name="email"
                            id="email"
                            type="email"
                            className="form-control"
                            placeholder="Your email*"
                          />
                            <ErrorMessage name="email" component="div" />

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormGroup className="mt-2">
                          <Field
                            required
                            name="subject"
                            type="text"
                            className="form-control"
                            id="subject"
                            placeholder="Your Subject.."
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormGroup className="mt-2">
                          <Field
                            name="message"
                            id="message"
                            rows="4"
                            className="form-control"
                            placeholder="Your message..."
                            style={Message_text_Style}
                            type="textbox"
                          ></Field>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} className="text-end">
                        <input
                          type="submit"
                          disabled={isSubmitting}
                          id="submit"
                          name="send"
                          className="submitBnt btn btn-primary"
                          value="Send"
                          />
                        <div id="simple-msg"></div>
                      </Col>
                    </Row>
                    <Modal 
                    visible={this.state.visible}
                    width="450"
                    height="350"
                    effect="fadeInUp"
                    onClickAway={() => this.closeModal()}
                >
                    <div>
                        <h3 className="home-title" style={Alert_text_Style}><br /> Thank you!</h3>
                        <p className="pt-3 home-desc" style={Alert_text_Style}> We'll be in touch shortly. <br /> </p>
                        <a href="/#" className="btn-primary btn btn-none;height: 20px" style={Close_text_Style} onClick={() => this.closeModal()}> Close</a>
                    </div>
                </Modal>
                  </Form>
         
         
         
         {/* <Form>

                        <Field type="email" name="email" />


                        <Field className="mt-2" type="password" name="password" />


                        <button type="submit" disabled={isSubmitting}>

                          Submit

                        </button>

                      </Form> */}
                      
                      </>
       )}

     </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;

import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";


//Import Section Title
import SectionTitle from "../common/section-title";
import ProcessBox from "./ProcessBox";


class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processes: [
        {
          icon: "pe-7s-pen",
          title: "Describe what you need",
          desc: "Specify what kind of document is needed and provide some context. In seconds, FountainPen will generate text that would have taken hours to write.",
        },
        {
          icon: "pe-7s-note2",
          title: "Polish your draft",
          desc: "Get your ready-to-edit document and give it a personal touch, so the final masterpiece will be your own unique work product. You'll have total freedom.",
        },
        {
          icon: "pe-7s-target",
          title: "Deliver a high quality document",
          desc: "After polishing your draft, FountainPen will double-check everything - to help ensure all your arguments get the point across in the best possible way.",
        },
      ],
    };
  }

  
  render() {

//git push changes hack

    return (
      <React.Fragment>
        <section className="section bg-light" id="process">
          <Container>
            {/* section title */}
            <SectionTitle
              title="HOW IT WORKS"
              desc="
              Just tell FountainPen what you need, and it'll hand over a final draft for your approval. It's the most sophisticated and versatile writing instrument.
              "
            />

            <Row>
              <Col lg={6} className="text-center process-left-icon-1">
                <i className="pe-7s-angle-right"></i>
              </Col>
              <Col lg={6} className="text-center process-left-icon-2">
                <i className="pe-7s-angle-right" ></i>
              </Col>
            </Row>
            
            <Row className="mt-5">
              <ProcessBox processes={this.state.processes} />
              {/* <div className="text-center mx-auto">
                <button
                  className="btn btn-primary waves-light waves-effect mt-5" 
                >
                  Get Started <i className="mdi mdi-arrow-right"></i>
                </button>
              </div> */}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}



export default Process;

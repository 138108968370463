import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Modal from 'react-awesome-modal';
import { Formik } from 'formik';
import emailjs from "emailjs-com";
import ReactGA from 'react-ga';

const Alert_text_Style = {
  color: 'black',
  
};

const Close_text_Style = {
  marginLeft: "1%",
  marginTop: "40px",
};

class Section extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        visible : false
    }
}

openModal() {
  this.setState({
      visible : true
  });
}

closeModal() {
  this.setState({
      visible : false
  });
}


  render() {
    return (
      
      <React.Fragment>
        <section className="section bg-home home-half" id="home">
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="home-cell-bottom">
              <Container>
                <Row>
                  <Col
                    lg={{ size: 8, offset: 2 }}
                    className="text-white text-center"
                  >
                    
                    <h1 className="home-title">
                     A ghostwriter for busy people
                    </h1>
                    
                    <p className="pt-3 home-desc">                    
                    FountainPen™ uses AI to generate text that'll give you a head-start in the writing process and save tons of time.
                    </p>
                    <br />

                    <p className="pt-3 home-desc">
                    Sign-up for early access and a 10$ credit. 
                    </p>

                    <div className="text-center subscribe-form mt-5">
                     {/* subscription email start  */}
                    <Formik

                    initialValues={{ email: '', password: '' }}

                    validate={values => {

                    const errors = {};

                    if (!values.email) {

                    errors.email = 'Required';

                    } else if (

                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)

                    ) {

                    errors.email = 'Invalid email address';

                    }

                    return errors;

                    }}

                    onSubmit={(values, { setSubmitting }) => {
                     
                    setTimeout(() => {
                      emailjs.send("service_hzyawq5","template_4trm9uk",{
                        email: values.email,
                        });
                        this.openModal()
                    // alert(JSON.stringify("Thank you for subscribing. We will notify you as soon as FountainPen is launched"));

                    setSubmitting(false);
                    document.getElementById("contact-form").reset();
                    
                      ReactGA.event({
                      category: 'User',
                      action: 'Created an Account with the main signup button'
                    });

                  }, 400);

                    }}

                    >

                    {({

                    values,

                    errors,

                    touched,

                    handleChange,

                    handleBlur,

                    handleSubmit,

                    isSubmitting,

                    /* and other goodies */

                    }) => (

                    <form onSubmit={handleSubmit}>
                    
                    <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    id="email"
                    onChange={handleChange}

                    onBlur={handleBlur}

                    value={values.email}
                    
                    />

                    {errors.email && touched.email && errors.email}

                    <button type="submit" disabled={isSubmitting} color="none" className="btn-primary btn btn-none">

                    Sign up

                    </button>
                    <Modal 
                    visible={this.state.visible}
                    width="450"
                    height="350"
                    effect="fadeInUp"
                    onClickAway={() => this.closeModal()}
                >
                    <div>
                        <h3 className="home-title" style={Alert_text_Style}><br /> Thank you!</h3>
                        <p className="pt-3 home-desc" style={Alert_text_Style}> We'll send you your early-access user information before the official launch.<br /> </p>
                        <a href="/#" className="btn-primary btn btn-none;height: 20px" style={Close_text_Style} onClick={() => this.closeModal()}> Close</a>
                    </div>
                </Modal>
                    </form>

                    )}

                    </Formik>
                    <br />

                    {/* end of subscription form */}
                      
                      {/* <Form>
                        <input type="text" placeholder="Email" required />
                        <Button
                          color="none"
                          type="submit"
                          className="btn-primary">
                          Subscribe
                        </Button>
                      </Form> */}
                      <div>
                    </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;

import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
// import { Link } from "react-router-dom";

import { Link } from 'react-scroll';


class AboutUs extends Component {
  render() {

    return (
      <React.Fragment>
        <section className="section bg-light" id="features">
          <Container>
            <Row className="vertical-content">
              <Col lg={5}>
                <div className="features-box">
                  <h3>
                  With a few clicks, structure your arguments in a winning format while 
                  reducing costs and increasing profit
                  </h3>
                  <p className="text-muted web-desc">
              
                  Are you a lawyer who needs to file a legal brief? No problem. A journalist, a student who needs to meet deadlines - or an academic who has to file a paper for peer review? We got your back too.
                  <br /> <br /> FountainPen™ Law, FountainPen™ Pro, and FountainPen™ Academia can benefit anyone who wants to make writing their super-power. Sign-up now and let FountainPen work its magic for you.  
                  </p>
                  <ul className="text-muted list-unstyled mt-4 features-item-list">
                    <li className=""> 
                    The AI writing process is based on your inputs so that every document will perfectly fit your needs. FountainPen helps you craft arguments that can tilt the odds in your favor every time.

                    </li>
                    <li className="">
                    While other software solutions focus mainly on grammar, FountainPen helps you implement all the principles of persuasive writing.
                    </li>
                    <li className="">
                     <strong>You have complete privacy.</strong> Your data and the generated text stay private and not shared with anyone, so you can be completely comfortable using FountainPen.
                    </li>
                    <li className="">
                    FountainPen will save you the precious hours you used to spend during the initial writing process. With all of that time spared, you'll be able to focus on other things that matter.</li>
                  </ul>
                  <Link
                    to="home"  smooth={true} duration={1000}
                    className="btn btn-primary mt-5 waves-effect waves-light"
                    
                  >
                    Sign up <i className="mdi mdi-arrow-up"></i>
                  </Link>
                </div>
              </Col>
              <Col lg={7}>
                <div className="features-img features-right text-right">
                  <img
                    src="assets/images/FP-LOGO-HIGH-RES.png"
                    alt="FountainPen"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
